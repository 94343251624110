import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { FaDocker, FaPython, FaReact } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionBreak from "../components/section-break"
import styles from "./index.module.css"
import kubernetesLogo from "../../content/assets/kubernetes.svg"


class SiteIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const lauryImage = data.lauryImage.childImageSharp.fixed

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO />
        <main id={styles.main}>
          <section id={styles.about}>
            <div className={styles.profile_image}>
              <Img fixed={lauryImage} />
            </div>
            <div className={styles.content}>
              <h1>Howdy, comps!</h1>
              <p>
                I'm Laury Bueno, a software developer && journalist
              </p>
            </div>
          </section>
          <SectionBreak />
          <section id={styles.tools}>
            <h1>Tools of choice</h1>
            <div className={styles.content}>
              <div><FaDocker /></div>
              <div><img src={kubernetesLogo} style={{ color: `#555555` }} width="55%" height="55%" alt="" /></div>
              <div><FaPython /></div>
              <div><FaReact /></div>
            </div>
          </section>
          <SectionBreak />
            <section id={styles.more_about}>
              <h1>One bit about me</h1>
              <div className={styles.content}>
                <p>
                  As a software engineer, I'm a full stack web developer who has worked with platforms like Python, Go, PHP, React and Angular. On the server side of things, I'm in love with the Kubernetes ecosystem and the cloud environments where it thrives. Lately, my passion for quality software and data has driven me to work as a Machine Learning Engineer. In this new role, I put together all of my skills to create and care for end-to-end ML workflows.
                </p>
                <p>
                  As a journalist, I wrote pieces about science, sports and national affairs. I've also been a home page editor and social media manager for journalistic brands. Although these days are far behind me now, the obsession with clear and effective communication never went away.
                </p>
                <p>
                  In my free time, I enjoy working on a couple of <Link to="/projects">personal projects</Link> and writing about <Link to="/snippets">stuff I learn</Link>.
                </p>
              </div>
            </section>
        </main>
      </Layout>
    )
  }
}

export default SiteIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    lauryImage: file(absolutePath: { regex: "/content/assets/laury.png/" }) {
      childImageSharp {
        fixed(width: 247, height: 510) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
